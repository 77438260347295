var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-box"},[_c('div',{staticClass:"main-box"},[_c('div',{staticClass:"video-box"},[_vm._m(0),_c('div',{staticClass:"menu-box",class:{ 'menu-box-open': _vm.isDrawerOpen }},[_c('div',{staticClass:"drawer-btn",on:{"click":function($event){_vm.isDrawerOpen = !_vm.isDrawerOpen}}},[_c('img',{class:{ 'rotate-img': _vm.isDrawerOpen },attrs:{"src":require("@/assets/image/curriculum/open-Icon.png"),"alt":""}}),_c('span',[_vm._v("章节目录")])]),_c('div',{staticClass:"menu-list"},[_c('h3',{staticClass:"list-name"},[_vm._v("课程列表")]),_c('a-spin',{directives:[{name:"show",rawName:"v-show",value:(_vm.tree.length == 0),expression:"tree.length == 0"}],staticClass:"loading",attrs:{"tip":"加载中..."}}),_c('Tree',{attrs:{"tree":_vm.tree,"sectionId":Number(_vm.sectionId)},on:{"nodeClick":_vm.treeClick}})],1)])]),_c('div',{staticClass:"init-box"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.courseDetail.courseName))]),_c('span',{staticClass:"right"},[_vm._v(_vm._s(_vm.courseDetail.categoryName)+" | 视频数量："+_vm._s(_vm.courseDetail.classHour)+"个")])]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.courseDetail.introduction))]),(_vm.courseDetail.startTime)?_c('div',{staticClass:"row",style:('background-image:url(' +
          require('@/assets/image/curriculum/20220506-141106.png') +
          ')')},[_vm._m(1),(_vm.courseDetail.validDayType == 2)?_c('span',[_vm._v("长期")]):(_vm.courseDetail.startTime)?_c('span',[_vm._v("有效期："+_vm._s(_vm.courseDetail.startTime)+" 至 "+_vm._s(_vm.courseDetail.endTime))]):_vm._e()]):_vm._e(),(_vm.courseDetail.openStartTime)?_c('div',{staticClass:"row",style:('background-image:url(' +
          require('@/assets/image/curriculum/20220506-141113.png') +
          ')')},[_vm._m(2),_c('span',[_vm._v(_vm._s(_vm.courseDetail.className)+"（"+_vm._s(_vm.courseDetail.openStartTime)+" 至 "+_vm._s(_vm.courseDetail.openEndTime)+"）")])]):_vm._e()]),_c('div',{staticClass:"tabs-box"},[_c('div',{staticClass:"tabs"},[_c('a',{staticClass:"tabsdiv",class:_vm.tabIndex == 0 ? 'a1' : '',attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.tabIndex = 0}}},[_c('span',[_vm._v("课程介绍")])]),_c('a',{staticClass:"tabsdiv",class:_vm.tabIndex == 1 ? 'a2' : '',attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.tabIndex = 1}}},[_c('span',[_vm._v("目录")])]),_c('a',{staticClass:"tabsdiv",class:_vm.tabIndex == 2 ? 'a2' : '',attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.tabIndex = 2}}},[_c('span',[_vm._v("导师介绍")])]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.courseDetail.studentInstructions),expression:"courseDetail.studentInstructions"}],staticClass:"tabsdiv",class:_vm.tabIndex == 3 ? 'a2' : '',attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.tabIndex = 3}}},[_c('span',[_vm._v("学习指导")])])]),_c('div',{staticClass:"content"},[(_vm.tabIndex == 0 && !_vm.courseDetail.detail)?_c('a-empty',{staticStyle:{"padding-top":"50px"},attrs:{"image":require('@/assets/image/stateHint/icon_nodata.png'),"image-style":{
            height: '180px',
          }}}):(_vm.tabIndex == 0)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.courseDetail.detail)}}):_vm._e(),(_vm.tabIndex == 1 && !_vm.tree)?_c('a-empty',{staticStyle:{"padding-top":"50px"},attrs:{"image":require('@/assets/image/stateHint/icon_nodata.png'),"image-style":{
            height: '180px',
          }}}):(_vm.tabIndex == 1)?[_c('forTree',{attrs:{"tree":_vm.tree,"isHave":1,"courseId":_vm.courseDetail.courseId,"courseName":_vm.courseDetail.courseName,"sectionId":Number(_vm.sectionId)},on:{"nodeClick":_vm.treeClick}})]:_vm._e(),(_vm.tabIndex == 2 && !_vm.courseDetail.teacherList)?_c('a-empty',{staticStyle:{"padding-top":"50px"},attrs:{"image":require('@/assets/image/stateHint/icon_nodata.png'),"image-style":{
            height: '180px',
          }}}):(_vm.tabIndex == 2)?_vm._l((_vm.courseDetail.teacherList),function(item){return _c('div',{key:'teacherList' + item.teacherId},[_c('div',{staticClass:"teacher"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"picture",staticStyle:{"width":"152px"},attrs:{"src":item.squarePicture,"alt":""}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"border"}),_c('div',{staticClass:"triangle"})]),_c('div',{staticClass:"right"},[_c('swiper',{ref:"mySwiper",refInFor:true,staticClass:"titleList",attrs:{"options":_vm.teacherSwiperOptions}},[_vm._l((item.title),function(itemI,indexI){return _c('swiper-slide',{key:indexI},_vm._l((itemI),function(itemII,indexII){return _c('div',{key:indexII,staticClass:"titleItem"},[_c('span',{staticStyle:{"background":"#E5E5E5"}}),_vm._v(_vm._s(itemII)+" ")])}),0)}),_vm._l((item.title),function(itemI,indexI){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.title.length > 1),expression:"item.title.length > 1"}],key:indexI + 1,staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})})],2)],1)]),_c('div',{staticClass:"introduction",domProps:{"innerHTML":_vm._s(_vm.regtxt(item.introduction))}})])}):_vm._e(),(_vm.tabIndex == 3 && !_vm.courseDetail.studentInstructions)?_c('a-empty',{staticStyle:{"padding-top":"50px"},attrs:{"image":require('@/assets/image/stateHint/icon_nodata.png'),"image-style":{
            height: '180px',
          }}}):(_vm.tabIndex == 3)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.courseDetail.studentInstructions)}}):_vm._e(),(_vm.tabIndex == 4 && !_vm.courseDetail.registrationGuide)?_c('a-empty',{staticStyle:{"padding-top":"50px"},attrs:{"image":require('@/assets/image/stateHint/icon_nodata.png'),"image-style":{
            height: '180px',
          }}}):(_vm.tabIndex == 4)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.courseDetail.registrationGuide)}}):_vm._e()],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('video',{attrs:{"id":"player-container-id","preload":"auto","playsinline":"","webkit-playsinline":""}},[_vm._v(" 您的浏览器不支持 video 标签，请升级或更换其他的浏览器。 "),_c('br'),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"https://www.google.cn/chrome/"}},[_vm._v("Google Chrome 浏览器")]),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"text"},[_c('em',[_vm._v("有")]),_c('em',[_vm._v("效")]),_c('em',[_vm._v("期")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"text"},[_c('em',[_vm._v("班")]),_c('em',[_vm._v("级")]),_c('em',[_vm._v("期")]),_c('em',[_vm._v("数")])])}]

export { render, staticRenderFns }